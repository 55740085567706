import styles from './ContentList.css'

export function ContentListUnordered({ children, layoutClassName }) {
  return (
    <ContentListBase listStyle='u' className={cx(styles.componentUnordered, layoutClassName)}>
      {children}
    </ContentListBase>
  )
}

export function ContentListOrdered({ children, layoutClassName }) {
  return (
    <ContentListBase listStyle='o' className={cx(styles.componentOrdered, layoutClassName)}>
      {children}
    </ContentListBase>
  )
}

function ContentListBase({ children, className, listStyle }) {
  /** @type {any} */
  const ListBase = `${listStyle}l`

  return (
    <ListBase className={cx(styles.componentBase, className)}>
      {children}
    </ListBase>
  )
}
